import React from "react";
import "./SingleDetailsSidebar.css";
import { useTranslation } from "react-i18next";
import ContactButtons from "../../../components/UI/contact-btns/ContactButtons";
import FormatNumberHook from "../../../CustomHooks/FormatNumber/FormatNumberHook";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMoneyBillWave } from "@fortawesome/free-solid-svg-icons";
const SingleDetailsSidebar = ({ item }) => {
  const [t] = useTranslation();
  const [formatNumber] = FormatNumberHook();

  function calculatePercentage(part, whole) {
    const priceNumber = Number(whole.replace(/,/g, ""));
    if (priceNumber === 0) {
      return 0; // avoid division by zero
    }
    const percentage = (Number(part) / priceNumber) * 100;
    return parseFloat(percentage?.toFixed(0)) + "%";
  }
  if (!item?.price) return null;

  return (
    <div className="single-details-sidebar px-2">
      <div className="profile__agent__body">
        <div className="unit-price-info">
          <div className="price p-4 bg-sec-2 fs-36 radius-10 fw-medium text-white my-2">
            {formatNumber(Number(item?.price?.replace(/,/g, ""))) || "...."}
            {t("singleDetailsPage.singleDetailsSidebar.pound")}
          </div>
          {item?.installments?.length > 0 && (
            <div className="installment mb-3 d-flex gap-2 align-items-center justify-content-center">
              <div className="border border-1 rounded w-50 p-3">
                <p className="fw-medium color-primary fs-22 m-0">
                  {item?.installments?.[0]?.years}
                </p>
                <p> {t("singleDetailsPage.singleDetailsSidebar.years")}</p>
              </div>
              <div className="border border-1 rounded w-50 p-3">
                <p className="fw-medium color-primary fs-22 m-0">
                  {item?.installments?.[0]?.deposit &&
                    item?.price &&
                    calculatePercentage(
                      item?.installments?.[0]?.deposit,
                      item?.price
                    )}
                </p>
                <p> {t("singleDetailsPage.singleDetailsSidebar.deposit")}</p>
              </div>
            </div>
          )}
          {item?.installments?.length < 1 && (
            <div className="price p-3 bg-pri radius-10 fw-medium text-white my-2">
              <FontAwesomeIcon icon={faMoneyBillWave} />{" "}
              {t("singleDetailsPage.singleDetailsSidebar.cash")}
            </div>
          )}
        </div>
        <ContactButtons
          whatsapp={item?.user?.phone}
          phone={item?.user?.phone}
          email={item?.user?.email}
          instagram={item?.user?.instagram}
          facebook={item?.user?.facebook}
        />
        {item?.installments?.length > 0 && (
          <div className="pay-plans">
            <label className="txt-start w-100 fw-medium fs-22">
              خطة السداد
            </label>
            <div className="my-2 d-flex gap-2 align-items-center justify-content-center">
              {item?.installments?.slice(0, 3)?.map((plan, i) => {
                return (
                  <div className="border border-1 rounded w-50 p-3" key={i}>
                    <p className="fw-medium color-primary fs-22 m-0">
                      {calculatePercentage(plan?.deposit, item?.price)}
                    </p>
                    <p className="m-0">
                      {" "}
                      {t("singleDetailsPage.singleDetailsSidebar.deposit")}
                    </p>
                    <p className="m-0">
                      {plan.years}{" "}
                      {t("singleDetailsPage.singleDetailsSidebar.years")}
                    </p>
                  </div>
                );
              })}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default SingleDetailsSidebar;
